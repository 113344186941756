import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

export const EfficacyStatsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${SPACER.small} 0 ${SPACER.large} 0;

  ${MEDIA_QUERIES.mdUp} {
    width: 400px;
    padding-bottom: ${SPACER.xlarge};
  }
`;

export const EfficacyStatTitle = styled.p`
  ${({ theme }) => TYPOGRAPHY_STYLE.title(theme)};
  line-height: 1.1;
  font-size: 30px;
`;

export const EfficacyStatLabel = styled.p`
  display: flex;
`;

export const SuperscriptSymbol = styled.sup`
  font-size: 8px;
`;
