import React from 'react';

import {
  EfficacyStatsContainer,
  EfficacyStatTitle,
  EfficacyStatLabel,
  SuperscriptSymbol,
} from './EfficacyStats.style';

export interface EfficacyStatsProps {
  stats: { label: string; amount: string; superscriptSymbol?: string }[];
}

export const EfficacyStats = ({ stats }: EfficacyStatsProps) => (
  <EfficacyStatsContainer id="efficacy-stats">
    {stats.map((item) => (
      <div key={item.label}>
        <EfficacyStatTitle>{item.amount}</EfficacyStatTitle>
        <EfficacyStatLabel className="smallP">
          {item.label}
          {item?.superscriptSymbol && (
            <SuperscriptSymbol>{item.superscriptSymbol}</SuperscriptSymbol>
          )}
        </EfficacyStatLabel>
      </div>
    ))}
  </EfficacyStatsContainer>
);
